<script lang="ts" setup>
const links: Array<{ name: string; url: string }> = [
  {
    name: 'Account',
    url: '/settings'
  },
  {
    name: 'Handle',
    url: '/settings/handle'
  }
];
</script>

<template>
  <div class="flex overflow-x-auto md:overflow-x-visible md:flex-col">
    <NuxtLink
      v-for="(link, index) in links"
      :key="index"
      :to="link.url"
      class="settings-link"
    >
      {{ link.name }}
    </NuxtLink>
  </div>
</template>

<style lang="scss" scoped>
.settings-link {
  @apply flex items-center w-max md:w-full max-w-52 h-12 rounded-[10px] py-2.5 px-3.5 text-xl leading-[26px] text-brand-navy-700 font-medium;

  &.router-link-active {
    &.router-link-exact-active {
      @apply bg-brand-gray;
    }
  }
}
</style>
