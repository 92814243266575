<script lang="ts" setup>
import PageHeading from '@/components/common/PageHeading.vue';
import { useAuthStore } from '@/stores/auth';

const { logOut } = useAuthStore();
</script>

<template>
  <AppContainer>
    <AppHeader />
    <div class="h-full flex-1 bg-brand-white">
      <Container class-name="pt-10 md:pt-12 pb-4">
        <div class="flex justify-between items-center mb-6 md:mb-10">
          <Button
            size="sm"
            color="tertiary"
            style-name="square"
            start-icon="ic:round-chevron-left"
            class-name="!pl-2 !pr-3"
            :start-icon-props="{
              className: '!mr-0'
            }"
            to="/tournament"
          >
            Back
          </Button>
          <PageHeading class-name="mb-0 ml-3 mr-auto">Settings</PageHeading>
          <Button size="sm" class-name="!px-3 md:!px-5" @click="logOut">
            Log Out
          </Button>
        </div>
        <div class="grid gap-4 grid-cols-1 md:grid-cols-4">
          <SettingsNavigation />
          <div class="md:col-span-3">
            <slot />
          </div>
        </div>
      </Container>
    </div>
    <AppFooter />
  </AppContainer>
</template>
